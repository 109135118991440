import { Component, createRef, isValidElement, Fragment } from "preact";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from '../actions';
import _ from 'lodash';
import selectors from '../selectors';
import { PinManagerContext, updateHeights as updatePinContextHeights } from './pin-context';
import PageInfoContextProvider from './page/page-info-context';
import ScrollContextProvider from "./page/scroll-element";
import { CustomElementHost } from './page/register'
import UsesWatcher from './page/uses/uses';
import windowInfo from "./window-info"
import Backdrop from './page/backdrop/index'
import embeds from './page/embeds';
import { helpers } from "@cargo/common";
import Password from './page/password';
import EditPageButton from './overlay/edit-page-button';
import { getMobileOffsetsString, shallowEqual } from "../helpers";

const pageMap = new WeakMap();
const bodycopyMap = new WeakMap();
const pageContentMap = new WeakMap();
const pageLayoutMap = new WeakMap();

let resizeObserver;
let embedObserver;
let aboveViewportObserver;
let viewportBoundaryObserver;

const onAboveViewPortCallback = entries => {
	entries.forEach(function(entry){
		if( pageMap.has(entry.target) ){
			pageMap.get(entry.target).onAboveViewPort(entry)
		}
	});
}

let aboveViewportHeightCompensationRequired = 0;

if(!helpers.isServer) {

	resizeObserver = new ResizeObserver(function(entries){

		const updatedPageHeights = {};

		// flush above viewport records before handling resizes
		onAboveViewPortCallback(aboveViewportObserver.takeRecords())

		entries.forEach(function(entry){

			const pageComponent = pageMap.get(entry.target);

			if( pageComponent ){
				pageComponent.onResize(entry.contentRect)
				updatedPageHeights[pageComponent.props.id] = entry.contentRect.height;
			}

			if( bodycopyMap.has(entry.target) ){
				bodycopyMap.get(entry.target).onBodyCopyResize(entry.contentRect.width)
			}

			if( pageContentMap.has(entry.target) || pageLayoutMap.has(entry.target) ){

				var style = window.getComputedStyle(entry.target);

				let top = parseFloat(style.getPropertyValue('margin-top')) +
					parseFloat(style.getPropertyValue('padding-top')) +
					parseFloat(style.getPropertyValue('border-top'));

				let bottom = parseFloat(style.getPropertyValue('margin-bottom')) +
					parseFloat(style.getPropertyValue('padding-bottom')) +
					parseFloat(style.getPropertyValue('border-bottom'));

				if( pageContentMap.has(entry.target) ){

					const pageComponent = pageContentMap.get(entry.target);

					if(
						pageComponent.state.pageContentPad.top !== top
						|| pageComponent.state.pageContentPad.bottom !== bottom
					) {

						pageComponent.setState({
							pageContentPad: {
								top, bottom
							}
						})

					}

				} else {

					const pageComponent = pageLayoutMap.get(entry.target);

					if(
						pageComponent.state.pageLayoutPad.top !== top
						|| pageComponent.state.pageLayoutPad.bottom !== bottom
					) {
						
						pageComponent.setState({
							pageLayoutPad: {
								top, bottom
							}
						})

					}
				}

			}

		});

		// update all heights at once
		updatePinContextHeights(updatedPageHeights);

		// compensate for content height changes above the viewport
		if(aboveViewportHeightCompensationRequired !== 0) {
			document.scrollingElement.scrollTop += aboveViewportHeightCompensationRequired;
			aboveViewportHeightCompensationRequired = 0;
		}

	});

	aboveViewportObserver = new IntersectionObserver(onAboveViewPortCallback, {
		root: document,
		rootMargin: '0px 0px 0px 0px',
		threshold: [0,1]
	});

	viewportBoundaryObserver = new IntersectionObserver((entries, o) => {

		entries.forEach(function(entry){
			if( pageMap.has(entry.target) ){
				pageMap.get(entry.target).onViewportBoundaryIntersection(entry)
			}
		});

	}, {
		root: document,
		rootMargin: Math.max(screen.height * 6, 2500) + 'px',
		threshold: [0, 1]
	});

	embedObserver = new MutationObserver(function(changes){

		_.each(changes, function(mutation){

			if (mutation.type == 'childList') {
				
				mutation.addedNodes.forEach(function(node){
					
					if(node.nodeName === "CARGO-EMBED") {
						embeds.add(node);
					}

				});

			}
		});

	});

}

class Page extends Component {

	constructor(props) {
		
		super(props);

		this.bodycopyRef = createRef();
		this.pageContentRef = createRef();
		this.pageRef = createRef();
		this.pageLayoutRef = createRef();

		this.lastBodycopyRef = createRef();
		this.lastPageContentRef = createRef();
		this.lastPageRef = createRef();
		this.lastPageLayoutRef = createRef();

		this.currentHeight = 0;
		this.state = {
			resizeParentWidth: '100%',
			mobileOffsetsString: null,
			pageContentPad: {
				top: 0,
				bottom:0,
			},
			pageLayoutPad: {
				top: 0,
				bottom: 0,
			},
			windowHeight: null,
			fullyRender: true,
			lastPageLayoutHeight: 100
		}

		this.localStyleRef = createRef();

	}

	setMobileOffsets = () => {

		// if not mobile, clear the styles
		if (!this.props.isMobile) {
			this.setState({mobileOffsetsString: null})
			return;
		}

		// if no global stylesheet, then we can't calculate offsets
		if(!this.localStyleRef.current) return;

		const string = getMobileOffsetsString(this.localStyleRef.current.sheet, [
			{
				properties: [
					//'padding',
					'padding-top',
					'padding-right',
					'padding-bottom',
					'padding-left',
				],
				denyList: [
					'ul',
					'ol',
					'li',
					'h1',
					'h2',
					'h3',
					'h4',
					'h5',
					'h6',
					'sub',
					'sup',
					'small-caps'
				]
			}
		])

		this.setState({mobileOffsetsString: string})
	}

	onBodyCopyResize = (width) => {

		width = parseInt(width);

		if(this.lastWidth !== width) {

			this.setState({
				resizeParentWidth: width + 'px'
			})

			this.lastWidth = width;
		}

	}

	onWindowResize = (force)=>{

		this.setState(prevState=>{
			if( prevState.windowHeight === windowInfo.data.window.h ){
				return null;
			}
			return {
				windowHeight: windowInfo.data.window.h
			}
		})
	}

	onResize = (dimensions, batch = true) => {
		
		if(
			(this.isAboveViewport ?? this.pageRef.current?.getBoundingClientRect().bottom < 1)
			|| (
				// page is loaded in through an upwards pagination call. We need to offset it's height
				// so the viewport stays in the same position after first rendering this page.
				this.props.position === 'above' 
				&& this.firstResizeOccurred !== true
			)
		) {

			aboveViewportHeightCompensationRequired += dimensions.height - this.currentHeight;

			if(!batch) {
				document.scrollingElement.scrollTop += aboveViewportHeightCompensationRequired;
				aboveViewportHeightCompensationRequired = 0;
			}

		}

		this.currentHeight = dimensions.height;
		this.firstResizeOccurred = true;

	}

	onAboveViewPort = (intersection) => {

		// filter out bunk intersection entries
		if(intersection.rootBounds.height === 0) {
			return;
		}

		this.isAboveViewport = intersection.boundingClientRect.bottom < 0;

	}

	onViewportBoundaryIntersection = (intersection) => {

		// by default the page should only render when not way outside 
		let shouldFullyRender = intersection.isIntersecting;

		// There are a few scenarios in which pages cannot be hidden
		if(
			// always render pages on the server
			helpers.isServer
			// always render pins
			|| this.props.isPin
			// always render a page being edited
			|| this.props.isEditingPage
		) {
			// force render
			shouldFullyRender = true;
		}

		this.setFullRenderState(shouldFullyRender);

	}

	setFullRenderState = (shouldFullyRender, callback) => {

		this.setState({
			fullyRender: shouldFullyRender,
			lastPageLayoutHeight: this.pageLayoutRef.current?.getBoundingClientRect().height || 100
		}, callback);

	}

	updateAdminState = (payload) => {
		store.dispatch({
			type: 'UPDATE_ADMIN_STATE', 
			payload: payload
		});
	}

	zIndexLayerPosition = (value) => {
		return value?.toString().padStart(2, '0');
	}

	findPageAdjusteeStatus = (pageID) => {

		let adjusteeData = {top: null, bottom: null}

		let adjustees = _.pickBy(this.context?.adjustPairs, (key) => { 
			return key.adjusts.indexOf(this.props.pid) !== -1; 
		});

		if (_.keys(adjustees).length > 0) {
			_.each(adjustees, function(adjustee){
				adjusteeData[adjustee.location]	= adjustee.adjustedHeight;
			})
		}

		return adjusteeData;
	}

	determinePinCSS = () => {

		if(helpers.isServer) {
			return;
		}

		if (_.isEmpty(this.context.levelData)) return;

		let startZIndexLayer = 1,
			pinCSS = {},
			isPinned = this.context?.renderedPins && this.props.isPin,
			adjustData = this.findPageAdjusteeStatus(this.props.pid);

		// ADJUSTED PADDING
		pinCSS['paddingTop'] = adjustData['top'];
		pinCSS['paddingBottom'] = adjustData['bottom'];

		if (isPinned) {
			let pinData = this.context?.renderedPins[this.props.pid];

			if (pinData) {
				// Z-INDEX
				// going top>down, so deepest level is the highest index
				let pageDepth = pinData.depth,
					deepestDepth = _.keys(this.context.levelData?.levels)?.length+startZIndexLayer,
					layeringIndex = deepestDepth - pageDepth+startZIndexLayer,
					zIndex = layeringIndex + this.zIndexLayerPosition(100-(pinData?.pinSort));
				pinCSS['zIndex'] = pinData.type !== 'adjust' ? zIndex ?? null : null;

				if (localStorage.getItem('pin_testing') === 'true') {
					let pinLogData = {
						old_zIndex: layeringIndex + this.zIndexLayerPosition(100-(pinData?.sort)),
						new_zIndex: zIndex,
						pid: this.props.pid,
						pageList_sort: pinData?.sort, 
						renderedPageSort: pinData?.pinSort,
					}
					console.log(pinLogData);
				}
				

				let pageLevelLocation = this.context.levelData?.levels[pageDepth][pinData.location];

				// console.log('page level location', pageLevelLocation, adjustData)

				if (pinData.type === 'overlay') {
					// TOP/BOTTOM value
					pinCSS[pinData.location] = pageLevelLocation.startingHeight - adjustData[pinData.location];

				} else if (pinData.type === 'adjust') {
					// if an adjust only page
					if (adjustData[pinData.location] !== null) {
						// if receiving adjust, use the start height
						pinCSS[pinData.location] = !pageLevelLocation.adjustsSelf ? pageLevelLocation.startingHeight - adjustData[pinData.location] : null
					} else {

						// if not receiving adjust, use the start height + sum of pages in sort below it (bottom = above it)
						//pinCSS[pinData.location] = 
							//pageLevelLocation.startingHeight;
							// + pageLevelLocation.adjusterHeight;
							// + this.getAdjustOnlyPinStartHeight(pageDepth, pinData?.sort, pinData.location);
					}
					
				}
				
			}

		}

		pinCSS['--pin-padding-top'] = (pinCSS['paddingTop'] || 0)+'px';
		pinCSS['--pin-padding-bottom'] = (pinCSS['paddingBottom'] || 0)+'px';

		// return all the CSS as an object
		return !_.isEmpty(pinCSS) ? pinCSS : null ;

	}

	getAdjustOnlyPinStartHeight = (depth, sort, location) => {

		let priorHeights = 0

		_.each(this.context.renderedPins, (pin, id)=> {
			if ( pin.depth === depth 
				&& pin.location === location
				&& (location === 'top' ? pin.sort < sort : pin.sort > sort)
				&& pin.type === 'adjust'
			) {
				priorHeights += this.context.pageHeights[id];
			}
		})

		return priorHeights;
	}

	determinePinClassList(pinOptions) {

		if (!pinOptions) return '';

		let classList = '';
		classList += ' pinned pinned-'+pinOptions.position;
		classList += pinOptions.fixed ? ' fixed' : pinOptions.overlay ? ' overlay' :  ''

		return classList;

	}

	editPage = (e) => {

		e.preventDefault()
		// e.stopPropagation();
		parent.editorActivationEvent = e;
		parent.navigateAdmin('/' + this.props.pid)

		this.updateAdminState({'pageTitleBarTransition': true})
		setTimeout(()=>{
			this.updateAdminState({'pageTitleBarTransition': false})
		}, 600)

	}

	getPageInfoContextValue() {

		if(
			this.props.isEditingPage !== this.lastProps?.isEditingPage
			|| this.props.pid !== this.lastProps?.pid
		) {

			// only generate new context if needed
			this.pageInfoContextValue = {
				isEditing: this.props.isEditingPage,
				pid: this.props.pid
			}

		}

		this.lastProps = this.props;

		return this.pageInfoContextValue

	}

	render() {
		if(!this.props.hasPage) {
			return null;
		}

		if(helpers.isServer && this.props.access_level !== 'public') {
			// do not server render non-public content
			return null;
		}

		let pageJSX = null,
			pinRelatedCSS = null,
			pinClassList = null;

		const hasBackdrop = this.props.backdrops?.activeBackdrop && this.props.backdrops?.activeBackdrop != 'none',
			  isPinned = this.props.isPin && this.props.pin_options,
			  pinOptions = isPinned ? this.props.pin_options : null;

		if(this.state.fullyRender) {

			pinClassList = this.determinePinClassList(pinOptions);
			pinRelatedCSS = this.determinePinCSS();

			// get all of the padding and margins together that we've gathered and use them to set a 'max fit height' for media items
			let pad = this.props.contentPad.top +
				this.state.pageLayoutPad.top +
				this.state.pageContentPad.top +
				this.state.pageContentPad.bottom +
				this.state.pageLayoutPad.bottom +
				this.props.contentPad.bottom;

			if( pinRelatedCSS ){

				if (pinRelatedCSS.paddingTop ){
					pad += pinRelatedCSS.paddingTop;
				}

				if (pinRelatedCSS.paddingBottom ){
					pad += pinRelatedCSS.paddingBottom;
				}
			}

			let maxFitHeight = Math.max(10, this.state.windowHeight - pad);
			let pageContent = null;

			if(this.props.access_level === 'password') {

				pageContent = <Password target={this.props.pid} onSucces={() => {
					this.props.fetchContent(this.props.pid, {
						force: true
					});
				}}/>

			} else {
				if(this.hasOwnProperty('lockedContent')) {
					// do not attempt to render page content twice. This is extremely important
					// for the admin so we can assume control over the page content. Bad things 
					// will happen if both preact and the admin modify this
					pageContent = this.lockedContent;
				} else {
					pageContent = this.lockedContent = this.props.content;
				}
			}

			this.maxFitHeight = maxFitHeight;
			const bodyCopyJSX = <bodycopy
				style={{
					'--fit-height': maxFitHeight + 'px',
					'--resize-parent-width': this.state.resizeParentWidth
				}}
				ref={this.bodycopyRef}
				dangerouslySetInnerHTML={ typeof pageContent === "string" ? {__html: pageContent} : undefined }
			>
				{isValidElement(pageContent) ? pageContent : null}
			</bodycopy>

			pageJSX = (
				<>
					<PageInfoContextProvider
						pageRef={this.pageRef}
						value={this.getPageInfoContextValue()}
					>
						<div className="page-layout" ref={this.pageLayoutRef}>
							<div className="page-content" ref={this.pageContentRef}>
								{this.props.adminMode && this.props.Editor_PageEditorOutlines !== false && 
									<EditPageButton
										bodycopyRef={this.bodycopyRef}
										pageTitle={this.props.title}
										pageContent={this.props.content}
										pageIsPin={this.isPinned}
										isEditingPage={this.props.isEditingPage}
										editPage={this.editPage}
									/>
								}
								<UsesWatcher
									pageInfo={this.getPageInfoContextValue()}
									adminMode={this.props.adminMode}
									bodycopyRef={this.bodycopyRef}
								>
									<CustomElementHost portalHost={bodyCopyJSX} />
									{bodyCopyJSX}
								</UsesWatcher>
							</div>
						</div>

					</PageInfoContextProvider>
				</>
			)

		} else {
			pageJSX = <div style={{height: this.state.lastPageLayoutHeight + 'px'}}></div>
		}

		return (
			<div 
				id={this.props.pid}
				page-url={this.props.purl?.toLowerCase()}
				className={`page${pinClassList !== null ? pinClassList : ''}`}
				ref={this.pageRef}
				style={pinRelatedCSS}
				editing={this.props.isEditingPage}
			> 
				{hasBackdrop && <Backdrop id={this.props.pid}  settings={this.props.backdrops} />}
				<a id={this.props.purl}></a>
				<ScrollContextProvider passthrough={!pinOptions?.fixed} scrollingElement={this.pageContentRef}>
					{pageJSX}
				</ScrollContextProvider>

				{/* the inline style tag for local CSS. Don't comment this out as the admin requires it for local style previewing */}
				<style ref={this.localStyleRef}>{this.props.local_css}</style>
				<style id={`mobile-offset-styles-${this.props.pid}`}>{this.state.mobileOffsetsString}</style>
			</div>
		)

		
	}

	componentDidUpdate = (prevProps, prevState, prevContext) => {

		this.bindObservers();

		this.setPinProperties(prevProps);

		if(this.state.fullyRender === true && prevState.fullyRender === false) {

			// the page is fully rendered but was sparse before
			this.onFullComponentRender();

		} else if(this.state.fullyRender === false && prevState.fullyRender === true) {

			// the page is sparse but was fully rendered bfore
			this.onSparseComponentRender();

		}

		// ACTIVE LINKS
		// check active links as routing occurs
		if ((prevProps.activePURL !== this.props.activePURL)
			|| 
			// check when going in and out of preview
			(prevProps.adminMode !== this.props.adminMode)
		) {
			this.checkAndSetActiveLinks();
			this.updateShowCartLinkCounts();
		}

		if( this.props.local_css !== prevProps.local_css ) {
			this.setMobileOffsets();
		}

		if(prevProps.isMobile !== this.props.isMobile) {
			this.setMobileOffsets();
		}

		if(this.props.hashPurl !== prevProps.hashPurl) {
			this.runHashScroll();
		}

	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {

		// Don't attempt to render if no props/state/context has changed
		if(
			this.props === nextProps 
			&& this.state === nextState
			&& this.context === nextContext
		) {
			return false;
		}

		if(
			// we're using locked content
			this.hasOwnProperty('lockedContent')
			// and content prop changed
			&& this.props.content !== nextProps.content
			// but everything else is still equal
			&& shallowEqual({...this.props, content: null}, {...nextProps, content: null})
			&& this.state === nextState
			&& this.context === nextContext
		) {
			// no need to render
			return false;
		}

		return true;

	}

	setPinProperties = (prevProps) => {

		if(!this.props.isPin) {
			return;
		}

		if(!this.pageRef.current) {
			return;
		}

		// scroll bottom pins into view when making changes via the editor
		if (this.props.adminMode && _.isEqual(this.props.pin_options, prevProps.pin_options) === false) {

			this.pageRef.current.scrollIntoView();

			if (this.props.pin_options.position === 'bottom') {
				
				let adjustPair = this.context.adjustPairs[this.props.pid];

				if (adjustPair && adjustPair.adjusts) {
					let adjustedEl = document.body.querySelector("[id='"+adjustPair.adjusts[0]+"']");	
					requestAnimationFrame(()=>{
						adjustedEl.scrollIntoView({block: "end"})
					});
				} 
			}

		}

		if (this.props.pin_options.fixed === true) {
			this.setFixedPinScrollability();
		}

		// add an 'accepts-pointer-events' class for pages / page content containers with a backdrop or background color
		if (this.props.pin_options.overlay === true && this.props.local_css !== '') {
			
			// debounce this as during window resizing this causes a lot of expensive repaints
			this.checkBackgroundColor();

		}
	}

	checkBackgroundColor = _.debounce(() => {

		if(!this.pageRef.current) {
			return;
		}

		const hasBackdrop = this.props.backdrops?.activeBackdrop && this.props.backdrops?.activeBackdrop != 'none';
			
		if (!hasBackdrop && this.hasNoBackgroundColor(this.pageRef.current)) {
			this.pageRef.current.classList.remove('accepts-pointer-events')
		} else {
			this.pageRef.current.classList.add('accepts-pointer-events')
		}

		let pageContentContainer = this.pageRef.current?.querySelector('.page-content');
		
		if (this.hasNoBackgroundColor(pageContentContainer)) {
			pageContentContainer.classList.remove('accepts-pointer-events')
		} else {
			pageContentContainer.classList.add('accepts-pointer-events')
		}

	}, 100)

	hasNoBackgroundColor = (element) => {
		if (element == undefined) {return false;}

		let styles = window.getComputedStyle(element, null),
			bg_color = styles.getPropertyValue("background-color"),
			opacity = (bg_color.match('rgba')) ? bg_color.replace(/^.*,(.+)\)/,'$1') : null;

		return bg_color == undefined || bg_color == 'transparent' || bg_color == 'initial' || opacity == 0;
	}

	setFixedPinScrollability = () => {

		if(!this.pageRef.current) {
			return;
		}

		let pageContentContainer = this.pageContentRef.current;
		let pageBodycopyContainer = this.bodycopyRef.current;

		if (
			pageContentContainer 
			&& pageBodycopyContainer 
			&& pageBodycopyContainer.clientHeight > pageContentContainer.clientHeight
		) {
			this.pageRef.current.classList.add('allow-scroll')
		} else {
			this.pageRef.current.classList.remove('allow-scroll')
		}
	}

	checkAndSetActiveLinks = (e) => {

		if(!this.pageRef.current) {
			return;
		}

		let activeFilter = e?.detail?.filter
		if( activeFilter?.startsWith('tag:') ){
			activeFilter = activeFilter.replace('tag:', '').trim();
		} else if( activeFilter) {
			activeFilter = 'all';
		} else {
			activeFilter = null;
		}

		const isInAdmin = this.props.inAdminFrame && this.props.adminMode === true;
		// If editing in the admin, do not set active links, or they will be stored in CRDT
		// isInAdmin prevents the active class from being added while in the admin.

		if (this.props.isEditingPage && !isInAdmin) return;
		// get all 'rel="history"' links and apply 'active' when matching the active PURL
		let links = this.pageRef.current.querySelectorAll('[rel="history"], [rel="filter-index"]');

		_.each(links, (link) => {

			let purl = link.getAttribute("href")?.toLowerCase();
			let tags = link.getAttribute('data-tags')?.toLowerCase();
			let filterIndex = link.getAttribute('rel') === 'filter-index';

			if (
				!isInAdmin &&
				(
					purl === this.props.activePURL || this.props.activePurlParents?.includes(purl)

					// if it's a tag match
					// || (activeFilter && tags && tags.trim() === activeFilter ) 

					// or if it's an 'all' tag link and an 'all' filter
					// || (activeFilter && activeFilter =='all' && filterIndex && !tags )
				)
			) {
				link.classList.add('active');

			} else 
			// if(
			// 	// (!e && !filterIndex) ||
			// 	// ( e && filterIndex ) ||
			// 	// isInAdmin
			// ) 
			{
				link.classList.remove('active');
			}
		})
		
	}

	bindObservers = (willUnmount=false)=>{

		if( this.pageRef.current !== this.lastPageRef.current || willUnmount ){
			
			if( this.lastPageRef.current){
				pageMap.delete(this.lastPageRef.current);
				resizeObserver.unobserve(this.lastPageRef.current);
				aboveViewportObserver.unobserve(this.lastPageRef.current);
				viewportBoundaryObserver.unobserve(this.lastPageRef.current);
			}

			if( this.pageRef.current && !willUnmount){
				pageMap.set(this.pageRef.current, this);
				resizeObserver.observe(this.pageRef.current);
				aboveViewportObserver.observe(this.pageRef.current);
				viewportBoundaryObserver.observe(this.pageRef.current);
			}

			this.lastPageRef.current = this.pageRef.current;

		}

		if( this.pageLayoutRef.current !== this.lastPageLayoutRef.current || willUnmount ){

			if( this.lastPageLayoutRef.current ){
				pageLayoutMap.delete(this.lastPageLayoutRef.current);
				resizeObserver.unobserve(this.lastPageLayoutRef.current);
			}

			if( this.pageLayoutRef.current && !willUnmount){
				pageLayoutMap.set(this.pageLayoutRef.current, this);
				resizeObserver.observe(this.pageLayoutRef.current);
			}
			this.lastPageLayoutRef.current = this.pageLayoutRef.current;
		}

		if( this.pageContentRef.current !== this.lastPageContentRef.current || willUnmount ){

			if( this.lastPageContentRef.current){
				pageContentMap.delete(this.lastPageContentRef.current);
				resizeObserver.unobserve(this.lastPageContentRef.current);
			}
			if( this.pageContentRef.current && !willUnmount ){
				pageContentMap.set(this.pageContentRef.current, this);
				resizeObserver.observe(this.pageContentRef.current);
			}
			this.lastPageContentRef.current = this.pageContentRef.current;
		}

		if( this.bodycopyRef.current !== this.lastBodycopyRef.current || willUnmount ){

			if( this.lastBodycopyRef.current){
				this.lastBodycopyRef.current.removeEventListener('filter-thumbnail-index', this.checkAndSetActiveLinks);
				bodycopyMap.delete(this.lastBodycopyRef.current);
				resizeObserver.unobserve(this.lastBodycopyRef.current);
			}

			if( this.bodycopyRef.current && !willUnmount){
				bodycopyMap.set(this.bodycopyRef.current, this);
				resizeObserver.observe(this.bodycopyRef.current);
				this.bodycopyRef.current.addEventListener('filter-thumbnail-index', this.checkAndSetActiveLinks);
			}

			this.lastBodycopyRef.current = this.bodycopyRef.current;

		}

	}

	runHashScroll() {

		if(this.props.hashPurl === this.props.purl) {

			if(this.state.fullyRender !== true) {
				// if page is rendered in sparse mode because it's far out of view:
				// render the page first, then scroll to it
				this.setFullRenderState(true, () => {
					this.pageRef.current.scrollIntoView();
				})
			} else {
				this.pageRef.current.scrollIntoView();
			}

		}

	}

	componentDidMount() {

		this.runHashScroll();
		this.onWindowResize();
		windowInfo.on('window-resize', this.onWindowResize)
		
		if(this.state.fullyRender === true) {
			this.onFullComponentRender();
		}

		// let pin context know about our element instantly
		this.context.onPageMount(this.pageRef.current);

		if(this.pageRef.current) {
			
			// find initial embeds
			embeds.findEmbedsIn(this.pageRef.current);

			// observe new embeds
			if(embedObserver) {
				embedObserver.observe(this.pageRef.current, { childList: true, subtree: true });
			}
			
			// reload embeds after dom binding was attached
			this.pageRef.current.addEventListener('dom-binding-initialized', () => {
				embeds.findEmbedsIn(this.pageRef.current)
			});

		}

	}

	onFullComponentRender() {
		
		if(this.pageLayoutRef.current) {

			function markNodeAsSaveable(node) {
				node.childNodes.forEach(node => node.setSaveable(true));
				for (const child of node.childNodes) {
					markNodeAsSaveable(child);
				}
			}

			markNodeAsSaveable(this.pageLayoutRef.current);

		}

		if(this.pageRef.current) {

			// handle scripts
			this.initializeScriptTags();
			this.props.pageMounted(this.pageRef.current).then(() => {

				// wait till the page mount call is complete. If we have a DOM binding for this 
				// page it'll sync the page to what's in the CRDT, overwriting any differences
				// that these functions might have caused
				this.checkAndSetActiveLinks();
				this.updateShowCartLinkCounts();
				this.fetchCommerceProductsByLink();

			})
		}

		if (this.props.isPin && this.props.pin_options?.fixed === true) {
			this.setFixedPinScrollability();
		}

		if(this.props.isMobile) {
			this.setMobileOffsets();
		}

		this.bindObservers();

	}

	componentWillUnmount() {

		windowInfo.off('window-resize', this.onWindowResize);

		this.onSparseComponentRender(true);

 		// manually resize to 0 height
		this.onResize({ height: 0 }, false);

		// let pin context know instantly
		this.context.onPageUnmount(this.pageRef.current);

	}

	onSparseComponentRender(willUnmount=false) {

		this.bindObservers(willUnmount);

		if(this.pageRef.current) {
			this.props.pageUnMounted(this.pageRef.current);
		}

	}

	fetchCommerceProductsByLink = () => {

		if(!this.pageRef.current) {
			return;
		}

		if (!this.props.has_shop) return;

		let links = this.pageRef.current.querySelectorAll('[rel="add-to-cart"], [rel="add_to_cart"]');
		let linkedProductIDs = [];

		_.each(links, (link) => {
			let productID = link.getAttribute("data-product");

			if (productID) {
				linkedProductIDs.push(productID);
			}
		})

		this.props.fetchCommerceProducts({
			idArray: linkedProductIDs,
			onlyNew: true
		})
	}

	updateShowCartLinkCounts = () => {

		if(!this.pageRef.current) {
			return;
		}

		// having a shop is required
		if (!this.props.has_shop) return;
		// if no data in the cart, there's no count to update
		if (!this.props.cartData || _.isEmpty(this.props.cartData)) return;
		// do not update cart count text in admin mode
		if (this.props.adminFrame) return;


		let cartCount = _.keys(this.props.cartData).length;
		let links = this.pageRef.current.querySelectorAll('a[rel="show-cart"], a[rel="show_cart"]');

		_.each(links, (link) => {
			if (link.hasAttribute("show-count")) {
				let unTouchedText = link.innerHTML.replace(/ *\([^)]*\) */g, "");
				link.innerText = unTouchedText + ' ('+cartCount+')';
			}
		})
		
	}

	initializeScriptTags = () => {

		let errorOccured = false;
		const onError = function(e){
			errorOccured = true;
		}

		window.addEventListener('error', onError, false);

		_.each(this.bodycopyRef.current?.querySelectorAll('script'), script => {

			// these are non-executable scripts - pass over them
			if( script.getAttribute('type') === 'text/thumbnail-metadata'){
				return;
			}

			// clear any unrelated errors
			errorOccured = false;

			// need to create a new script node for it to be executed
			const newScriptNode = document.createElement('script');

			// copy over attributes
			[...script.attributes].forEach( attr => { 
				newScriptNode.setAttribute(attr.nodeName, attr.nodeValue) 
			})

			// set script body
			newScriptNode.innerHTML = script.textContent;

			// insert it
			script.replaceWith(newScriptNode);

			if(errorOccured) {
				
				// reset
				errorOccured = false;

				// replace script with a commented out version
				newScriptNode.parentNode.insertBefore(document.createComment(newScriptNode.outerHTML), newScriptNode);
				newScriptNode.remove();

				if(this.props.adminMode) {
					// alert('One or more scripts on this page are broken and were disabled.');
					window.store.dispatch({
						type: 'UPDATE_FRONTEND_STATE', 
						payload: {
							alertModal: { 
								message: 'One or more scripts on this page are broken and were disabled.',
								type: 'notice'
							}
						}
					});
				}

			}

		});

		window.removeEventListener('error', onError);

	}

}

Page.contextType = PinManagerContext;

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		pageMounted         : actions.pageMounted,
		pageUnMounted       : actions.pageUnMounted,
		updateFrontendState : actions.updateFrontendState,
		fetchContent        : actions.fetchContent,
		fetchCommerceProducts: actions.fetchCommerceProducts,
	}, dispatch);

}

const getParentPurls = _.memoize((parentSetList, state) => {

	// get parent pids and map them to purls
	return parentSetList.map(pid => state.sets.byId[pid]?.purl)
		// remove any invalid data
		.filter(purl => purl && typeof purl === "string")
		// convert purls to lowercase
		.map(purl => purl.toLowerCase());

});


export default connect(
	(state, ownProps) => {

		const page = selectors.getContentById(state, ownProps.id);

		const activeContent = (state.pages.byId[state.frontendState.activePID] || state.sets.byId[state.frontendState.activePID]);
		let activePURL;
		let activePurlParents;

		if(activeContent) {
			activePURL = activeContent.purl?.toLowerCase();
			activePurlParents = getParentPurls(selectors.getParentSetList(state, activeContent.id), state)
		}

		if(!helpers.isServer && !activePURL) {
			activePURL = window.location.pathname.replace(/^\//, '').toLowerCase();
		}

		return {
			inAdminFrame				: state.frontendState.inAdminFrame,
			isMobile 					: state.frontendState.isMobile,
			adminMode					: state.frontendState.adminMode,
			isEditingPage 				: page?.id && state.frontendState.PIDBeingEdited === page?.id,
			hasPage 					: page !== undefined,
			pid							: page?.id,
			purl						: page?.purl,
			isPin						: page?.pin,
			title						: page?.title,
			backdrops					: page?.backdrops,
			content						: page?.content,
			access_level				: page?.access_level,
			local_css					: page?.local_css,
			pin_options					: page?.pin_options,
			PIDBeingEdited 				: state.frontendState.PIDBeingEdited,
			activePID					: state.frontendState.activePID,
			activePURL					: activePURL,
			activePurlParents			: activePurlParents,
			pageDepth					: selectors.getParentSetList(state, ownProps.id).length -1,
			has_shop					: state.site.shop_id !== null,
			cartData					: state.commerce?.cart,
			Editor_PageEditorOutlines	: state.adminState?.localStorage?.Editor_PageEditorOutlines,
		}
	},
	mapDispatchToProps
)(
	Page
)
